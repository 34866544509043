import React from "react";
import { Grid, Container, Box } from "@mui/material";
import { happyClients } from "./constants/happyClients";
import HappyClientDetails from './happyClientDetails';
const ourHappyClients = happyClients.map((clients) => <HappyClientDetails comment={clients.comment} clientName={clients.clientName} location={clients.location} img={clients.img} />)

const HappyClients = () => {
    return (
        <div className="mainClassWrapper">
                <div style={{ fontSize: '50px', fontWeight: '700', display: 'flex', justifyContent: 'center', marginTop: '40px' }}>Happy Clients</div>
                <hr style={{ height: '2px', width: '70px', backgroundColor: 'orange', border: '1px solid orange', marginTop: '15px' }}></hr>
                <div style={{ display: 'flex', justifyContent: 'center',marginTop:'20px', textAlign: 'center', fontSize: '18px'}}>Customer happiness is the level of loyalty and satisfaction that our customers experience after engaging with us. It's the feeling that comes from our customers having their needs met regularly, at the right time, in the right way. Here you can see some of our happy customers.</div>
                <div className="happyClientMain" style={{marginTop:'40px'}}>
                    <div className="happyClientEach">{ourHappyClients}</div>
                </div>
        </div>

    );

}

export default HappyClients;

