import React, { useState } from "react";
import { Button, Grid } from '@mui/material';
import logo from './images/logo.png';
import { Link } from 'react-scroll';
import AboutUs from "./aboutUs";
import TourPackages from "./tourPackages";
import Gallery from "./gallery";
import HappyClients from "./happyClients";
import ContactUs from "./contactUs";
import Footer from "./footer";
import GetStart from "./getStart";
import './header.css';
import DialogBox from "./dialog";
import ModalPopup from "./modal";
import { FaBars } from 'react-icons/fa';

const style = {

    backgroundColor: 'orange',
    color: 'white',
    '&:hover': {
        backgroundColor: 'orange',
    },
    padding: '7px',
    width: '120px',
    fontWeight: '600', fontSize: '15px',
    borderRadius: '7px'
}

const button = <Button variant="contained" sx={style}>GET QUOTE</Button>
const listItems =
    [
        {
            id: 1,
            title: 'Home',
            link: <GetStart />,
        },

        {
            id: 2,
            title: 'About Us',
            link: <AboutUs />,
        },

        {
            id: 3,
            title: 'Tour Packages',
            link: <TourPackages />,
        },

        {
            id: 4,
            title: 'Gallery',
            link: <Gallery />,
        },

        {
            id: 5,
            title: 'Happy Clients',
            link: <HappyClients />,
        },

        {
            id: 6,
            title: 'Contact Us',
            link: <ContactUs />,
        },
        {
            id: 7,
            title: button,
            link: "",
        },
    ];
const Header = () => {
    const [open, setOpen] = useState(false);
    const openList = () => {
        open === true ? setOpen(false) : setOpen(true);
    }
    const myData = listItems.map(menu => (
        <Link to={menu.title} smooth={true} offset={-50} duration={500} >
            <div style={{ marginLeft: '-60%' }} ><ul ><li style={{ display: 'block' }}>{menu.title}</li></ul></div></Link>
    ))
    return (
        <>
            <div style={{ width: '100%', overflowX: 'hidden',zIndex:'5' }}>
                <Grid container className="header-wrapper" style={{}}>
                    <div style={{ marginTop: '15px', width: '32%' }} >
                        <img style={{ height: '55px', width: '240px' }} src={logo} alt="" />
                    </div>
                    <div style={{ marginTop: '30px', width: '60%', height: 'fit-content' }} >
                        <div className="displayItems" >
                            {listItems.map(menu => (
                                <Link to={menu.title} smooth={true} offset={-50} duration={500} >
                                    <div style={{ marginLeft: '15px', display: 'inline-block', cursor: 'pointer' }}>{menu.title}</div></Link>
                            ))}
                        </div>
                        <div className="hideItems">
                            <div style={{ display: 'block' }}>
                                <Button
                                    variant="contained"
                                    onClick={openList}
                                    sx={{ backgroundColor: 'white', boxShadow: '0', "&:hover": { backgroundColor: 'white', boxShadow: '0' } }}
                                >

                                    <FaBars size={25}
                                        color="black"
                                        style={{ border: '1px solid black', borderRadius: '5px', padding: '10px', display: 'block', backgroundColor: 'white' }}
                                    />
                                </Button>
                            </div>
                        </div>
                        {
                            open &&
                            <div style={{ width: '100%' }} >
                                {myData}</div>
                        }

                    </div>

                </Grid>
                <div>
                    {listItems.map(menu => (
                        <div id={menu.title} >{menu.link}</div>
                    ))}
                </div>
                <Footer />
                <DialogBox />
                <ModalPopup />
            </div>
        </>
    );
}

export default Header;

