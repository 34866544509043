import { Box, Container } from '@mui/material'; 

import React from 'react'; 

const TermsConditions=()=>{ 

return( 

    <> 

    <Container> 

        <Box> 

        <div style={{ fontSize: '50px', fontWeight: '700', display: 'flex', justifyContent: 'center', marginTop: '40px' }}>Terms & Conditions</div> 

 
 

<hr style={{ height: '2px', width: '70px', backgroundColor: 'orange', border: '1px solid orange', marginTop:'20px' }}></hr> 

<div style={{display: 'flex', justifyContent: 'center',fontSize:'17px', marginTop:'20px'}}> 

All correspondence(s) in respect of Tours / Travel Services booking should be addressed to www.dreamflyholiday.com 

</div> 

<div style={{fontSize:'17px'}}> 

<div style={{ fontSize: '20px', fontWeight: '600', marginTop: '40px', marginBottom:'10px' }}>Payment/Booking Policy 

</div> 

<div> 

1st Payment: 30% at the time of booking. 

</div> 

<div>2nd Payment: 50% of the after hotel confirmation voucher.</div> 

<div>3rd Payment: 20% of the booking amount before 7 days of starting the trip.</div> 

</div> 

<div style={{fontSize:'17px'}}> 

<div style={{ fontSize: '20px', fontWeight: '600', marginTop: '40px', marginBottom:'10px'  }}>Cancellation Policy 

</div> 

<div> 

25% is non-refundable. 

</div> 

<div>45 days prior to the departure date: 50% of package cost will be deducted.</div> 

<div>30 days prior to the departure date: 75% of package cost will be deducted.</div> 

<div>15 days prior to departure date: 100% non-refundable.</div> 

</div> 

<div style={{fontSize:'17px'}}> 

<div style={{ fontSize: '20px', fontWeight: '600', marginTop: '40px' , marginBottom:'10px' }}>Mode of Payment</div> 

<div>Overseas advance payment can be made through Wire Transfer to our bank.</div> 

</div> 

<div style={{marginTop:'40px', display:'inline-block', fontSize:'17px'}}><div style={{fontWeight:'800', display:'inline-block'}}>Please Note:</div> We will not be responsible for any costs arising out of unforeseen circumstances like landslides, road blocks, bad weather, etc.</div> 

 
 

        </Box> 

    </Container> 

             </> 

); 

} 

export default TermsConditions; 

 