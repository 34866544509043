import React, { useState, useEffect,useRef } from 'react';
import { Button, Box, Container, Grid, Typography, Modal, Dialog } from '@mui/material';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './main.css';
import ReactWhatsapp from 'react-whatsapp';
import CloseIcon from '@mui/icons-material/Close';
import emailjs from '@emailjs/browser';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const DialogBox = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        const timer = setTimeout(() => {
            setOpen(true);
        }, 2000)
        return () => clearTimeout(timer);
    }
    useEffect(() => {
        handleOpen();
    }, [])

  
    const [openQuery, setOpenQuery] = useState(false);
    const [values, setValues] = useState("")
    const style = {
        backgroundColor: 'orange',
        color: 'white',
        "& : hover": {
            backgroundColor: 'orange',
            color: 'white',
        },
        display: 'inline-block', fontWeight: '600',

    }
    const styles = {
        position: 'absolute',
        top: '10%',
        left: '20%',
        border: '2px solid #000',
        boxShadow: 24,
        backgroundColor: 'white',
        width: 700,
        outline: 'none'
    };

    const options = [
        {
            value: 1,
            label: "Leanne Graham"
        },
        {
            value: 2,
            label: "Ervin Howell"
        }
    ];

    const handleOpenQuery = () => {
            setOpenQuery(true);
    }

    const handleCloseQuery = () => setOpenQuery(false);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [travellingFrom, setTravellingFrom] = useState('');
    const [travellingTo, setTravellingTo] = useState('');
    const [arrivalDate, setArrivalDate] = useState('');
    const [returnDate, setReturnDate] = useState('');
    const [snakeBar, setSnakeBar] = useState(false);
    const form = useRef('');
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_juwg5yr', 'template_wpjqmaa', form.current, 'mhKR7MXu_HnJfHnc9')
            .then((result) => {
                setSnakeBar(true);
                setFirstName('');
                setEmail('');
                setContactNumber('');
                setTravellingFrom('');
                setTravellingTo('');
                setArrivalDate('');
                setReturnDate('');
            }, (error) => {
                console.log(error.text);
            });
    };

    const handleCloseSnakebar = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnakeBar(false);
    };

    const handleDialClick=()=>{
        const phoneNumber="9643751779";
        const dialerUrl=`tel:${phoneNumber}`
        window.location.href = dialerUrl
    }
    return (
        <>
          <Snackbar open={snakeBar} autoHideDuration={6000} onClose={handleCloseSnakebar}>
                    <Alert onClose={handleCloseSnakebar} severity="success" sx={{ width: '100%' }}>
                      Your Query has been sent successfully!
                    </Alert>
                </Snackbar>
            <div style={{ backgroundColor: '#212529', width: '100%' }}>
                <div className='dialogboxmain'>
                    <div className="callnowbutton">
                        <a href="#" onClick={handleDialClick}>
                    <Button
                    variant="contained"
                        sx={{ width: '330px', backgroundColor: 'blue', color: 'white','&:hover': {
                            backgroundColor: 'blue',
                          }, }}>
                        <PhoneInTalkIcon
                            sx={{
                                backgroundColor: 'blue',
                                color: 'white', padding: '3px',
                                borderRadius: '5px',
                                marginRight: '20px'
                            }}
                        />
                        call now</Button>
                        </a>
                    </div>
                    <div className="whatsappbutton">
                    <ReactWhatsapp number="+91 9643751779" message="Hi, I am intrested in your tour packages." style={{backgroundColor:'green',borderRadius: '5px',border:'0',}}>
                    <Button variant="contained" sx={{ width: '330px', backgroundColor: 'green', color: 'white','&:hover': {
                            backgroundColor: 'green',
                          }, }}>
                    
                        <WhatsAppIcon
                            sx={{
                                backgroundColor: 'green',
                                color: 'white', padding: '3px',
                                borderRadius: '5px', marginRight: '20px'
                            }}
                        />
                        connect on whatsapp
                        
                    </Button>
                   </ReactWhatsapp>
                    </div>
                    <div className='sendenquirybutton'>
                    <Button
                    onClick={handleOpenQuery}
                    variant="contained"
                        sx={{ width: '330px', height: '40px', backgroundColor: 'orange', color: 'white','&:hover': {
                            backgroundColor: 'orange',
                          }, }}>
                        send enquiry
                    </Button>
                    </div>
                </div>
            </div>

            <Modal
                open={openQuery}
                onClose={handleCloseQuery}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='backColoe' style={{
                    position: 'absolute',
                    top: '10%',
                    left: '20%',
                    border: '2px solid #000',
                    boxShadow: 24,
                    backgroundColor: 'white',
                    outline: 'none'
                }}>
                    <div className='display-flex'>
                    <div className="besttourdetails" >Get The Best Tour Package Deals</div>
                    <div >
                        <CloseIcon onClick={handleCloseQuery} sx={{ cursor: 'pointer' }} />
                    </div>
                    </div>
                    <form ref={form} onSubmit={sendEmail}>
                    <div className='contactUstextarea'>
                        <input type="text" className='inputBox'  value={firstName} onChange={((e) => setFirstName(e.target.value))} name="from_name" required placeholder="First Name" />
                    </div>
                    <div className='contactUstextarea'>
                        <input type="text" value={contactNumber} onChange={((e) => setContactNumber(e.target.value))}  name="contact_number" required placeholder="Mobile" className='inputBox' />
                    </div>
                    <div className='contactUstextarea'>
                        <input value={email} onChange={((e) => setEmail(e.target.value))} type="email" name="from_email" required placeholder="Email Address" className='inputBox' />
                    </div>
                    <div className='contactUstextarea'>
                        <input type="text" value={travellingFrom} onChange={((e) => setTravellingFrom(e.target.value))} name="travelling-from" required placeholder="I Am Traveeling From" className='inputBox' />
                    </div>
                    <div className='contactUstextarea'>
                        <input type="text" value={travellingTo} onChange={((e) => setTravellingTo(e.target.value))} name="travelling-to"  required placeholder="I Am Traveeling To" className='inputBox' />
                    </div>
                    <div className="modalCalender" >
                        <div className="arrivalDate" >
                            <label style={{ display: 'block' }}>Arrival Date</label>
                            <input type="date" className='dateInput' value={arrivalDate} onChange={((e) => setArrivalDate(e.target.value))} name="arrival-date" required placeholder="Arrival Date" />
                        </div>
                        <div className="returnDate">
                            <label style={{ display: 'block' }}>Return Date</label>
                            <input type="date" className='dateInput' value={returnDate} onChange={((e) => setReturnDate(e.target.value))} name="return-date" required placeholder="Return Date" />
                        </div>
                    </div>
                    <Grid container marginTop={3} marginBottom={3} justifyContent="center">
                        <Grid item xs={8} sm={8} md={8}   >
                            <Button type="submit" sx={{ backgroundColor: 'orange', color: 'white', width: '100%','&:hover': {
                            backgroundColor: 'orange',
                          }, }}>Plan my trip</Button>
                        </Grid>
                    </Grid>
                    </form>
                </div>
            </Modal>
        </>
    );
}
export default DialogBox;

