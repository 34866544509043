import React from "react";

import aboutusImage from './images/aboutusImage.png';
import { Container, Box } from "@mui/material";
import HeadphonesIcon from '@mui/icons-material/Headphones';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import './main.css';

const AboutUs = () => {
    return (
        <div className="mainClassWrapper">
                <div style={{ fontSize: '50px', fontWeight: '700', display: 'flex', justifyContent: 'center', marginTop: '40px' }}>About Us</div>
                <div>
                    <hr style={{ height: '2px', width: '70px', backgroundColor: 'orange', border: '1px solid orange' }}></hr>
                    <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', fontSize: '18px',marginTop:'20px',marginBottom:'40px'}}>DreamFLY Holiday is a tour and travel company based in Noida. We provide modified travel solution for clients all over the world. We handle our clients request with great attention concentrate on every details and help them their holidays hassle-free memorable for them.</div>
                    <div className="parent">
                        <div className="daughter"><img src={aboutusImage} alt="" style={{ height: '515px', width: '100%'}} /></div>
                        <div className="son">
                            <div item xs={12} justifyContent="center" style={{ width: '470px' }}>
                                <div className="whydreamfly" style={{ fontSize: '42px', fontWeight: '600' }}>
                                    Why DreamFLY Holiday?</div>
                                <div style={{ fontSize: '17px' }}>When we think about holiday, it means a pre-arrangement, prepaid trip for every individuals as per his/her budget. Which we easily provide.</div>
                                <div className="mainClassAbout" style={{ marginTop: '20px' }}>
                                    <div className="aboutfirstClass">
                                        <HeadphonesIcon sx={{ color: 'white', backgroundColor: 'orange', fontSize: '40px', padding: '11px' }} />
                                    </div>
                                    <div className="aboutsecondClass">
                                        <div style={{ fontSize: '20px', fontWeight: '600' }}>Excellent service & support</div>
                                        <div>DreamFLY Holiday provide hassle-free experience to the customer.</div>
                                    </div>
                                </div >
                                <div className="mainClassAbout" style={{ marginTop: '20px' }}>

                                    <div className="aboutfirstClass"><Diversity3Icon sx={{ color: 'white', backgroundColor: 'orange', fontSize: '40px', padding: '11px' }} /> </div>
                                    <div className="aboutsecondClass" >
                                        <div style={{ fontSize: '20px', fontWeight: '600' }}>A personalised experience</div>
                                        <div>We provide customer experiences that are tailored to the consumer's individual needs.</div>
                                    </div>
                                </div>
                                <div className="mainClassAbout" style={{ marginTop: '20px' }}>
                                    <div className="aboutfirstClass"><ShoppingCartIcon sx={{ color: 'white', backgroundColor: 'orange', fontSize: '40px', padding: '11px' }} /> </div>
                                    <div className="aboutsecondClass">
                                        <div style={{ fontSize: '20px', fontWeight: '600' }}>Budget friendly deals</div>
                                        <div>We give opportunity to the travelers to finding the best deals on airfare, hotels etc.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    );

}

export default AboutUs;

