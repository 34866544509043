import React from 'react'; 

const TourPackagesDetails=(props)=>{ 
return( 
    <> 
     <div style={{border:'1px solid #B7B7B7', borderRadius:'10px',margin:'10px'}}>  
     <img key={props.id} src={props.imageURL} alt="" style={{  height: '200px', width: '100%' , borderRadius:'10px'}}></img> 
                    <div style={{ marginLeft: '15px', marginRight: '15px' }}> 
                        <div style={{ fontSize: '22px', fontWeight: '600', marginTop: '5px' }}>{props.city}</div> 
                        <div style={{ fontWeight: '500', marginTop: '5px' }}>4{props.location}</div> 
                        <div style={{ fontSize: '13px', marginTop: '5px' }}>{props.forContact}</div> 
                        <div style={{ color: 'orange', fontSize: '18px', fontWeight: '800', marginTop: '10px', marginBottom: '15px' }}>{props.price}</div> 
                    </div> 
     </div> 
    </> 
); 
} 
export default TourPackagesDetails; 

 