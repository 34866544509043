import React from 'react'; 
import {Grid, Button } from '@mui/material'; 
import TourPackagesDetails from './tourPackagesDetails'; 
import { tourPackages } from './constants/tourPackages'; 
import Carousel from 'react-multi-carousel'; 
import 'react-multi-carousel/lib/styles.css'; 
import { Link, useNavigate } from 'react-router-dom'; 
import GetStart from './getStart';

const responsive = { 
    superLargeDesktop: { 
        breakpoint: { max: 4000, min: 3000 }, 
        items: 5,
        slidesToSlide:5, 
    }, 
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
      }
}; 

const myDetails = tourPackages.map(item=>( 
<TourPackagesDetails key={item.id} imageURL={item.imageURL} city={item.city} location={item.location} forContact={item.forContact} price={item.price} /> 
)) 

const TourPackages = () => { 
  const navigate=useNavigate()
    return ( 
        <> <Grid className="mainClassWrapper">
            <div style={{ fontSize: '50px', fontWeight: '700', display: 'flex', justifyContent: 'center', marginTop: '40px' }}>Tour Packages</div> 
            <div><hr style={{ height: '2px', width: '70px', backgroundColor: 'orange', border: '1px solid orange' }}></hr></div> 
            <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', fontSize: '18px'}}>When we say tour package, it means a pre-arrangement, prepaid trip that combines two or more travel components like airfare, airport transfer, accommodation, and other services. Here we providing you a complete range of various tour packages.</div> 
            <div style={{ marginTop: '40px' }}> 
                <Carousel responsive={responsive} autoPlay={true} autoPlaySpeed={2000}  customTransition=".4"  > 
              {myDetails} 
                </Carousel> 
            </div> 
            {/* <Grid sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Link to={()=><GetStart/>}>
              <Button sx={{ color: 'orange', fontWeight: '600', fontSize: '14.45px' }}>
                See More
              </Button>
              </Link>
              </Grid>  */}
        </Grid>
        </> 
    ); 
} 
export default TourPackages; 

 