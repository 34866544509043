import { Grid } from "@mui/material";
import React from "react";
import logo from './images/logo.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Link } from "react-router-dom";
import './main.css'

const Footer = () => {
    const newTab = (url) => {
        window.open(url);
    }
    const handleEmail=()=>{
        const email ='info@dreamflyholiday.com'
        window.location.href = `mailto:${email}`
    }
    return (
        <>
            <div style={{ backgroundColor: '#212529', width: '100%' }}>
                <div className="footerMain">
                    <div className="footerFirstContent">
                        <div className="firstClass">
                            <div>
                                <img src={logo} alt="" style={{ height: '70px', width: '200px' }} />
                            </div>
                            <div style={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '17px', marginTop: '20px' }}>
                                A tour & travel company, provide modified travel solution for clients all over the world.
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <a href="https://www.facebook.com/profile.php?id=100085047715808&mibextid=JRoKGi" target="_blank" rel="noreferrer">
                                <FacebookIcon onClick={()=>newTab("https://www.facebook.com/profile.php?id=100085047715808&mibextid=JRoKGi")} sx={{ color: 'white', backgroundColor: 'orange', padding: '4px', borderRadius: '4px' }} />
                                </a>
                                <a href="https://www.instagram.com/dreamflyholiday?igsh=MXBhdmtrbG1jeGgzNg==" target="_blank" rel="noreferrer">
                                <InstagramIcon onClick={()=>newTab("https://www.instagram.com/dreamflyholiday?igsh=MXBhdmtrbG1jeGgzNg==")} sx={{ color: 'white', backgroundColor: 'orange', padding: '4px', borderRadius: '4px', marginLeft: '5px' }} />
                                </a>
                                <a href="https://www.youtube.com/@dreamflyholiday4935" target="_blank" rel="noreferrer">
                                <TwitterIcon onClick={()=>newTab("https://www.youtube.com/@dreamflyholiday4935")} sx={{ color: 'white', backgroundColor: 'orange', padding: '4px', borderRadius: '4px', marginLeft: '5px' }} />
                                </a>
                                <a href="https://www.youtube.com/@dreamflyholiday4935" target="_blank" rel="noreferrer">
                                <YouTubeIcon onClick={()=>newTab("https://www.youtube.com/@dreamflyholiday4935")} sx={{ color: 'white', backgroundColor: 'orange', padding: '4px', borderRadius: '4px', marginLeft: '5px' }} />
                                </a>
                            </div>
                        </div>
                        <div className="secondClass">
                            <div style={{ fontSize: '21px', fontWeight: '500', color: 'white' }}>Legal Policy</div>
                            <div>
                                <hr align="left" style={{ height: '1.5px', width: '30px', backgroundColor: 'orange', border: '1px solid orange' }}></hr>
                            </div>
                            <div style={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '17px' }}>
                                <div style={{ marginTop: '20px', cursor:'pointer'  }}>
                                    Disclaimer
                                </div>
                                <div style={{ marginTop: '7px', cursor:'pointer'  }}>
                                    <Link style={{ textDecoration: 'none', color: 'rgba(255, 255, 255, 0.7)' }} to="/termsconditions">Terms & Conditions</Link>
                                </div>
                                <div style={{ marginTop: '7px', cursor:'pointer'  }}>
                                    <Link style={{ textDecoration: 'none', color: 'rgba(255, 255, 255, 0.7)' }} to="/termsconditions">Payment Mode</Link>
                                </div>
                                <div style={{ marginTop: '7px', cursor:'pointer'  }}>
                                    Privacy Policy
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footerSecondContent">
                    <div className="thirdClass">
                        <div style={{ fontSize: '21px', fontWeight: '500', color: 'white' }}>Links</div>
                        <div>
                            <hr align="left" style={{ height: '1.5px', width: '30px', backgroundColor: 'orange', border: '1px solid orange' }}></hr>
                        </div>
                        <div style={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '17px', }}>
                            <div style={{ marginTop: '20px', cursor:'pointer' }}>
                                Home
                            </div>
                            <div style={{ marginTop: '7px', cursor:'pointer'  }}>
                                About Us
                            </div>
                            <div style={{ marginTop: '7px', cursor:'pointer'  }}>
                                Tour Packages
                            </div>
                            <div style={{ marginTop: '7px', cursor:'pointer'  }}>
                                Testimonials
                            </div>
                            <div style={{ marginTop: '7px', cursor:'pointer'  }}>
                                Contact Us
                            </div>
                        </div>
                    </div>
                    <div className="forthClass">
                        <div style={{ fontSize: '21px', fontWeight: '500', color: 'white' }}>Contact Us</div>
                        <div>
                            <hr align="left" style={{ height: '1.5px', width: '30px', backgroundColor: 'orange', border: '1px solid orange' }}></hr>
                        </div>
                        <div style={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '17px' }}>
                            <div style={{ marginTop: '20px' }}>
                                Sector 53, Noida
                            </div>
                            <div style={{ marginTop: '7px' }}>
                            9643751779, 7042104648
                            </div>
                            <a style={{color:'white'}} href="https://mail.google.com/mail/u/0/#inbox?compose=VpCqJPtmstbhCjzsbMzFCgrHrlXcxpSSgNJvHQkthLhqNpgpGGDfxBLWZgKRpJkmjPhmTqL" target="_blank" rel="noreferrer">
                            <div onClick={handleEmail} style={{ marginTop: '7px', cursor:'pointer'  }}>
                                info@dreamflyholiday.com
                            </div>
                            </a>

                            <div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <hr style={{ backgroundColor: 'gray', marginTop: '40px' }} />
                <Grid columns={12}> <div style={{ display: "flex", justifyContent: 'center', color: 'rgba(255, 255, 255, 0.7)', marginTop: "5px", fontSize: '17px' }}>Copyright © 2022 DreamFLY Holiday. All rights reserved.</div></Grid>
            </div>
        </>
    );
}
export default Footer;

