import uttarakhand from '../images/tourPackageImages/uttarakhand.jpg'
import Goa from '../images/tourPackageImages/goa.jpg'
import shimla from '../images/tourPackageImages/Shimla.jpg'
import spiti from '../images/tourPackageImages/spiti.jpg'
import sikkim from '../images/tourPackageImages/sikkim.jpg'
import Andaman from '../images/tourPackageImages/andaman.jpg'
import Laddakh from '../images/tourPackageImages/ladakh.jpg'
import Nepal from '../images/tourPackageImages/nepal.jpg'
import Mauritius from '../images/tourPackageImages/mauritius.jpg'
import Bhutan from '../images/tourPackageImages/bhutan.jpg'
import Bali from '../images/tourPackageImages/bali.jpg'
import Thailand from '../images/tourPackageImages/thailand.jpg'
import Banglore from '../images/tourPackageImages/banglore.jpg'
import Chardham from '../images/tourPackageImages/chardham.jpg'
import Rajasthan from '../images/tourPackageImages/rajesthan.jpg'
import Kashmir from '../images/tourPackageImages/kashmir.jpg'
export const tourPackages=[ 

 { 

    id:1, 

    imageURL:uttarakhand, 

    city:'Uttrakhand', 

    location:'4N/5D Tour Package', 

    forContact:'For more information related to it, feel free to contact us.', 

    price:'18,000/-' 

 }, 

 { 

    id:2, 

    imageURL:Goa,

    city:'Goa', 

    location:'3N/4D Tour Package', 

    forContact:'For more information related to it, feel free to contact us.', 

    price:'10,000/-' 

 }, 

 { 

    id:3, 

    imageURL:shimla, 

    city:'Shimla Manali', 

    location:'5N/6D Tour Package', 

    forContact:'For more information related to it, feel free to contact us.', 

    price:'15,000/-' 

 }, 

 { 

    id:4, 

    imageURL:spiti, 

    city:'spiti', 

    location:'3N/4D Tour Package', 

    forContact:'For more information related to it, feel free to contact us.', 

    price:'15,000/-' 

 }, 

 { 

    id:5, 

    imageURL:sikkim, 

    city:'Sikkim', 

    location:'7N/8D Tour Package', 

    forContact:'For more information related to it, feel free to contact us.', 

    price:'20,000/-' 

 }, 

 { 

    id:6, 

    imageURL:Andaman, 

    city:'Andaman', 

    location:'5N/6D Tour Package', 

    forContact:'For more information related to it, feel free to contact us.', 

    price:'21,000/-' 

 }, 

 { 

    id:7, 

    imageURL:Laddakh, 

    city:'Laddakh', 

    location:'5N/6D Tour Package', 

    forContact:'For more information related to it, feel free to contact us.', 

    price:'17,000/-' 

 }, 

 { 

    id:8, 

    imageURL:Nepal, 

    city:'Nepal', 

    location:'3N/4D Tour Package', 

    forContact:'For more information related to it, feel free to contact us.', 

    price:'11,000/-' 

 }, 

 { 

    id:9, 

    imageURL:Mauritius, 

    city:'Mauritius', 

    location:'5N/6D Tour Package', 

    forContact:'For more information related to it, feel free to contact us.', 

    price:'20,000/-' 

 }, 

 { 

    id:10, 

    imageURL:Bhutan, 

    city:'Bhutan', 

    location:'6N/7D Tour Package', 

    forContact:'For more information related to it, feel free to contact us.', 

    price:'35,000/-' 

 }, 

 { 

    id:11, 

    imageURL:Bali, 

    city:'Bali', 

    location:'3N/4D Tour Package', 

    forContact:'For more information related to it, feel free to contact us.', 

    price:'23,000/-' 

 }, 

 { 

    id:12, 

    imageURL:Thailand, 

    city:'Thailand', 

    location:'5N/6D Tour Package', 

    forContact:'For more information related to it, feel free to contact us.', 

    price:'18,000/-' 

 }, 

 { 

    id:13, 

    imageURL:Banglore, 

    city:'Banglore', 

    location:'7N/8D Tour Package', 

    forContact:'For more information related to it, feel free to contact us.', 

    price:'40,000/-' 

 }, 

 { 

    id:14, 

    imageURL:Chardham, 

    city:'Chardham', 

    location:'9N/10D Tour Package', 

    forContact:'For more information related to it, feel free to contact us.', 

    price:'60,000/-' 

 }, 

 { 

    id:15, 

    imageURL:Rajasthan, 

    city:'Rajasthan', 

    location:'8N/9D Tour Package', 

    forContact:'For more information related to it, feel free to contact us.', 

    price:'52,000/-' 

 }, 

 { 

    id:16, 

    imageURL:Kashmir, 

    city:'Kashmir', 

    location:'5N/6D Tour Package', 

    forContact:'For more information related to it, feel free to contact us.', 

    price:'17,000/-' 

 }, 

]; 

 