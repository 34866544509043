import React from "react";
import galleryImage1 from './images/galleryImages/galleryImage1.png';
import galleryImage2 from './images/galleryImages/galleryImage2.png';
import galleryImage3 from './images/galleryImages/galleryImage3.png';
import galleryImage4 from './images/galleryImages/galleryImage4.png';
import galleryImage5 from './images/galleryImages/galleryImage5.png';
import galleryImage6 from './images/galleryImages/galleryImage6.png';
import { Grid, Container, Box } from "@mui/material";
import './header.css';

const Gallery = () => {
    return (
       <div className="mainClassWrapper" >
         <div style={{  display: 'flex' }}>
            <div>
                <div style={{ fontSize: '50px', fontWeight: '700', marginTop: '40px', display: 'block', textAlign: 'center' }}>Gallery</div>
                <hr style={{ height: '2px', width: '70px', backgroundColor: 'orange', border: '1px solid orange' }}></hr>
                <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', fontSize: '18px' }}>Images are used as important factors that affect decision making about travel destinations of tourists, influencing their behaviors, and reflecting their satisfaction of tourism places. Therefore, we providing here many types of images for you.</div>
                <div className="wrapper" style={{marginTop:'40px'}}>
                    <div className="colwrapper">
                        <div className="one"> <img src={galleryImage1} alt="" style={{borderRadius:'5px'}} /></div>
                    <div className="two"><img src={galleryImage2} alt="" style={{borderRadius:'5px'}}  /></div>
                    </div>
                    <div className="colwrapper">
                    <div className="two"><img src={galleryImage3} alt="" style={{borderRadius:'5px'}}  /></div>
                    <div className="one"><img src={galleryImage4} alt=""  style={{borderRadius:'5px'}} /></div>
                    </div>
                  <div className="colwrapper">
                  <div className="one"><img src={galleryImage5} alt=""  style={{borderRadius:'5px'}} /></div>
                    <div className="two"><img src={galleryImage6} alt=""  style={{borderRadius:'5px'}} /></div>
                  </div>
                </div>
            </div>
        </div>
       </div>
    );
}
export default Gallery;

