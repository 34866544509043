import './App.css'; 

import {Routes, Route, BrowserRouter} from 'react-router-dom'; 

import Header from './components/layout'; 

import TermsConditions from './components/termsConditions'; 

function App() { 

  return ( 

    <> 

   <BrowserRouter> 

   <Routes> 

   <Route path="/" element={  <Header />} /> 

   <Route path="termsconditions" element={<TermsConditions/>} /> 

   </Routes> 

   </BrowserRouter> 

 
 

    </> 

  ); 

} 

 
 

export default App; 

 
 

 