import React, {useState, useRef} from "react";
import { Button, Grid, Modal } from '@mui/material';
import BackgroundImage from './images/backgroundImage.png';
import FlightIcon from '@mui/icons-material/Flight';
import './main.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import CloseIcon from '@mui/icons-material/Close';
import emailjs from '@emailjs/browser';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const style = {
    backgroundColor: 'orange',
    color: 'white',
    '&:hover': {
        backgroundColor: 'orange',
    },
}
const styles = {
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
        backgroundColor: 'white',
    },
    display: 'inline-block',
    fontWeight: '600',
    marginLeft: '20px'
}

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
        slidesToSlide: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};
const GetStart = () => {

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [travellingFrom, setTravellingFrom] = useState('');
    const [travellingTo, setTravellingTo] = useState('');
    const [arrivalDate, setArrivalDate] = useState('');
    const [returnDate, setReturnDate] = useState('');
    const [snakeBar, setSnakeBar] = useState(false);
    const [openQuery, setOpenQuery] = useState(false);
    const form = useRef('');
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_juwg5yr', 'template_wpjqmaa', form.current, 'mhKR7MXu_HnJfHnc9')
            .then((result) => {
                setSnakeBar(true);
                setFirstName('');
                setEmail('');
                setContactNumber('');
                setTravellingFrom('');
                setTravellingTo('');
                setArrivalDate('');
                setReturnDate('');
            }, (error) => {
                console.log(error.text);
            });
    };

    const handleCloseSnakebar = (reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnakeBar(false);
    };

    const handleOpenQuery = () => {
        setOpenQuery(true);
}

const handleCloseQuery = () => setOpenQuery(false);
    return (
        <>
            <div style={{ width: '100%', height: '600px', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                <Carousel
                    additionalTransfrom={0}
                    autoPlay={true}
                    interval={2000}
                    showThumbs={false}
                    showIndicators={true}
                    centerMode={false}
                    swipeable={true}
                    infiniteLoop={true}
                    showArrows={false}
                >
                    <img src="https://i0.wp.com/blog.bambatravel.com/wp-content/uploads/2018/06/group-travel.jpg?fit=833%2C555&ssl=1" alt="" style={{ height: '550px', width: '100%'}} />
                    <img src="https://images.pexels.com/photos/1371360/pexels-photo-1371360.jpeg?cs=srgb&dl=pexels-te-lensfix-1371360.jpg&fm=jpg" alt="" style={{ height: '550px', width: '100%'}} />
                    <img src="https://okcredit-blog-images-prod.storage.googleapis.com/2021/05/tourandtravel1.jpg" alt="" style={{ height: '550px', width: '100%'}} />
                    <img src="https://www.livemint.com/lm-img/img/2023/08/17/600x338/Canada-Heat-4_1692272095427_1692272136344.jpg" alt="" style={{ height: '550px', width: '100%'}} />
                    
                </Carousel>
                <div style={{ position: 'absolute', color: 'white' }}>
                    <div style={{ fontSize: '2rem', fontWeight: '600', display: "flex", justifyContent: 'center', textAlign: 'center' }}>
                        It's time to go on Holiday<FlightIcon sx={{ fontSize: '30px', marginTop: '10px' }} /></div>
                    <div style={{ fontSize: '18px', display: "flex", textAlign: 'center', justifyContent: 'center', marginTop: '10px' }}>
                        Traveling to new places allows you to break out of your comfort zone and take on new experiences and challenges as you learn more about <br /> the world around you. Each place has its own unique culture and getting to experience this helps us grow psychologically.
                    </div>
                    <div style={{ display: "flex", justifyContent: 'center', marginTop: '50px' }}>
                        <Button onClick={handleOpenQuery} variant="contained" sx={style}> GET STARTED</Button>
                        {/* <Button variant="contained" sx={styles}>EXPLORE MORE</Button> */}
                    </div>
                </div>
            </div>
            <Modal
                open={openQuery}
                onClose={handleCloseQuery}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='backColoe' style={{
                    position: 'absolute',
                    top: '10%',
                    left: '20%',
                    border: '2px solid #000',
                    boxShadow: 24,
                    backgroundColor: 'white',
                    outline: 'none'
                }}>
                    <div className='display-flex'>
                    <div className="besttourdetails" >Get The Best Tour Package Deals</div>
                    <div >
                        <CloseIcon onClick={handleCloseQuery} sx={{ cursor: 'pointer' }} />
                    </div>
                    </div>
                    <form ref={form} onSubmit={sendEmail}>
                    <div className='contactUstextarea'>
                        <input type="text" className='inputBox'  value={firstName} onChange={((e) => setFirstName(e.target.value))} name="from_name" required placeholder="First Name" />
                    </div>
                    <div className='contactUstextarea'>
                        <input type="text" value={contactNumber} onChange={((e) => setContactNumber(e.target.value))}  name="contact_number" required placeholder="Mobile" className='inputBox' />
                    </div>
                    <div className='contactUstextarea'>
                        <input value={email} onChange={((e) => setEmail(e.target.value))} type="email" name="from_email" required placeholder="Email Address" className='inputBox' />
                    </div>
                    <div className='contactUstextarea'>
                        <input type="text" value={travellingFrom} onChange={((e) => setTravellingFrom(e.target.value))} name="travelling-from" required placeholder="I Am Traveeling From" className='inputBox' />
                    </div>
                    <div className='contactUstextarea'>
                        <input type="text" value={travellingTo} onChange={((e) => setTravellingTo(e.target.value))} name="travelling-to"  required placeholder="I Am Traveeling To" className='inputBox' />
                    </div>
                    <div className="modalCalender" >
                        <div className="arrivalDate" >
                            <label style={{ display: 'block' }}>Arrival Date</label>
                            <input type="date" className='dateInput' value={arrivalDate} onChange={((e) => setArrivalDate(e.target.value))} name="arrival-date" required placeholder="Arrival Date" />
                        </div>
                        <div className="returnDate">
                            <label style={{ display: 'block' }}>Return Date</label>
                            <input type="date" className='dateInput' value={returnDate} onChange={((e) => setReturnDate(e.target.value))} name="return-date" required placeholder="Return Date" />
                        </div>
                    </div>
                    <Grid container marginTop={3} marginBottom={3} justifyContent="center">
                        <Grid item xs={8} sm={8} md={8}   >
                            <Button type="submit" sx={{ backgroundColor: 'orange', color: 'white', width: '100%','&:hover': {
                            backgroundColor: 'orange',
                          }, }}>Plan my trip</Button>
                        </Grid>
                    </Grid>
                    </form>
                </div>
            </Modal>
        </>
    );
}
export default GetStart;

