import { Button, Modal } from '@mui/material';
import React, { useState, useEffect,useRef } from 'react';
import { Grid, Box } from '@mui/material';
import Select from "react-dropdown-select";
import CloseIcon from '@mui/icons-material/Close';
import './main.css';
import emailjs from '@emailjs/browser';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const ModalPopup = () => {
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState("")
    const style = {
        backgroundColor: 'orange',
        color: 'white',
        "& : hover": {
            backgroundColor: 'orange',
            color: 'white',
        },
        display: 'inline-block', fontWeight: '600',

    }
    const styles = {
        position: 'absolute',
        top: '10%',
        left: '20%',
        border: '2px solid #000',
        boxShadow: 24,
        backgroundColor: 'white',
        width: 700,
        outline: 'none'
    };

    const options = [
        {
            value: 1,
            label: "Leanne Graham"
        },
        {
            value: 2,
            label: "Ervin Howell"
        }
    ];

    const handleOpen = () => {
        const timer = setTimeout(() => {
            setOpen(true);
        }, 5000)
        return () => clearTimeout(timer);
    }

    useEffect(() => {
        handleOpen()
    }, [])
    const handleClose = () => setOpen(false);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });    
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [travellingFrom, setTravellingFrom] = useState('');
    const [travellingTo, setTravellingTo] = useState('');
    const [arrivalDate, setArrivalDate] = useState('');
    const [returnDate, setReturnDate] = useState('');
    const [snakeBar, setSnakeBar] = useState(false);
    const form = useRef('');
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_juwg5yr', 'template_wpjqmaa', form.current, 'mhKR7MXu_HnJfHnc9')
            .then((result) => {
                setSnakeBar(true);
                setFirstName('');
                setEmail('');
                setContactNumber('');
                setTravellingFrom('');
                setTravellingTo('');
                setArrivalDate('');
                setReturnDate('');
            }, (error) => {
                console.log(error.text);
            });
    };
    const handleCloseSnakebar = (reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnakeBar(false);
    };
    return (
        <>
          <Snackbar open={snakeBar} autoHideDuration={6000} onClose={handleCloseSnakebar}>
                    <Alert onClose={handleCloseSnakebar} severity="success" sx={{ width: '100%' }}>
                      Your Query has been sent successfully!
                    </Alert>
                </Snackbar>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='backColoe' style={{
                    position: 'absolute',
                    top: '10%',
                    left: '20%',
                    border: '2px solid #000',
                    boxShadow: 24,
                    backgroundColor: 'white',
                    outline: 'none'
                }}>
                    <div className='display-flex'>
                    <div className="besttourdetails" >Get The Best Tour Package Deals</div>
                    <div >
                        <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
                    </div>
                    </div>
              <form ref={form} onSubmit={sendEmail}>
              <div className='contactUstextarea'>
                        <input type="text" className='inputBox' value={firstName} onChange={((e) => setFirstName(e.target.value))} name="from_name" required placeholder="First Name" />
                    </div>
                    <div className='contactUstextarea'>
                        <input type="text"  value={contactNumber} onChange={((e) => setContactNumber(e.target.value))}  name="contact_number" required placeholder="Mobile" className='inputBox' />
                    </div>
                    <div className='contactUstextarea'>
                        <input value={email} onChange={((e) => setEmail(e.target.value))} type="email" name="from_email" required placeholder="Email Address" className='inputBox' />
                    </div>
                    <div className='contactUstextarea'>
                        <input type="text" value={travellingFrom} onChange={((e) => setTravellingFrom(e.target.value))} name="travelling-from" required placeholder="I Am Traveeling From" className='inputBox' />
                    </div>
                    <div className='contactUstextarea'>
                        <input type="text" value={travellingTo} onChange={((e) => setTravellingTo(e.target.value))} name="travelling-to" required placeholder="I Am Traveeling To" className='inputBox' />
                    </div>
                    <div className="modalCalender" >
                        <div className="arrivalDate" >
                            <label style={{ display: 'block' }}>Arrival Date</label>
                            <input type="date" value={arrivalDate} onChange={((e) => setArrivalDate(e.target.value))} name="arrival-date" className='dateInput' required placeholder="Arrival Date" />
                        </div>
                        <div className="returnDate">
                            <label style={{ display: 'block' }}>Return Date</label>
                            <input type="date" value={returnDate} onChange={((e) => setReturnDate(e.target.value))} name="return-date" className='dateInput' required placeholder="Return Date" />
                        </div>
                    </div>
                    <Grid container marginTop={3} marginBottom={3} justifyContent="center">
                        <Grid item xs={8} sm={8} md={8}   >
                            <Button type="submit" sx={{ backgroundColor: 'orange', color: 'white', width: '100%','&:hover': {
                            backgroundColor: 'orange',
                          }, }}>Plan my trip</Button>
                        </Grid>
                    </Grid>
              </form>
                </div>
            </Modal>
        </>

    );

}

export default ModalPopup;

