import backgroundImage from '../images/backgroundImage.png'; 

export const happyClients = [ 

    { 

        id: '1', 

       clientName:'Rahul', 

       location:' Karnataka', 

        comment: "I booked my Himachal Pradesh package with DreamFLY Holiday. It was a 6N/7D package, it went really smooth even I don't required to call anyone through out my journey.Huge thanks to DreamFLY Holiday team.", 

   img:{backgroundImage} 

    }, 

    { 

        id: '2', 

        clientName:'Preeti', 

       location:'Delhi', 

        comment: "I booked my Goa package with DreamFLY Holiday. And now I can assure you that they are working really hard to make sure each and everything to be in order. I enjoyed my trip a lot. Thanks to all team members.", 

        img:{backgroundImage} 

    }, 

    { 

        id: '3', 

        clientName:'Amandeep', 

       location:'Noida', 

        comment: "I booked Sikkim package with DreamFLY Holiday. They are very professional, they make changes in the itinerary as per my requirements. During the trip they called us on daily basis to ask is everything going well.", 

        img:{backgroundImage} 

    }, 

]; 

