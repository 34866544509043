import React from 'react';

import { Grid } from "@mui/material";

import GradeIcon from '@mui/icons-material/Grade';

import StarHalfIcon from '@mui/icons-material/StarHalf';

import Avatar from '@mui/material/Avatar';

import backgroundImage from './images/backgroundImage.png';




const HappyClientDetails = (props) => {
    return (

        <>
            <Grid item xs={3.61} sx={{ display: 'inline-block' }}>
                <div style={{ position: 'relative', padding: '20px', fontSize: '18px', marginLeft: '20px', borderRadius: '5px', backgroundColor: 'lightgrey' }}>
                    <GradeIcon sx={{ color: 'orange' }} />
                    <GradeIcon sx={{ color: 'orange' }} />
                    <GradeIcon sx={{ color: 'orange' }} />
                    <GradeIcon sx={{ color: 'orange' }} />
                    <StarHalfIcon sx={{ color: 'orange' }} />
                    <div>{props.comment}</div>
                </div>
                <Grid sx={{ marginTop: '35px', marginLeft: '20px'}} marginBottom={3}  container column={12}>
                    <Grid item xs={2}>
                        <Avatar style={{ fontSize: '35px' }}><img src={props.img.backgroundImage} alt="" /></Avatar>
                    </Grid>
                    <Grid sx={{ fontSize: '16px', fontWeight: '700' }} item >
                        {props.clientName},
                    </Grid>
                    <Grid item xs={{ fontSize: '14px', fontWeight: '500' }}>{props.location}</Grid>
                </Grid>
            </Grid>
        </>
    );
}
export default HappyClientDetails;

