import React, { useState, useRef, useEffect } from "react";
import { Grid, Container, Box, Button } from "@mui/material";
import Select from "react-dropdown-select";
import emailjs from '@emailjs/browser';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import './main.css';

const style = {
    backgroundColor: 'orange',
    color: 'white',
    fontWeight: '600',
    width: '100%',
    '&:hover': {
        backgroundColor: 'orange',
    },

}

const options = [
    {
        value: 2,
        label: "Leanne Graham"
    },
    {
        value: 3,
        label: "Ervin Howell"
    }
];
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ContactUs = () => {
    const [open, setOpen] = React.useState(false);
    const [firstName, setFirstName] = useState('');
    const [lasttName, setLasttName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [selectOption, setSelectOption] = useState('');
    const [message, setMessage] = useState('');
    console.log("slected option", selectOption)
    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const form = useRef('');

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_juwg5yr', 'template_wpjqmaa', form.current, 'mhKR7MXu_HnJfHnc9')
            .then((result) => {
                setOpen(true);
                setFirstName('');
                setLasttName('');
                setEmail('');
                setContactNumber('');
                setSelectOption('');
                setMessage('');
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <>
            <div className="mainClassWrapper" >
                <div style={{ fontSize: '50px', fontWeight: '700', display: 'flex', justifyContent: 'center', marginTop: '40px' }}>Contact Us</div>
                <div>
                    <hr style={{ height: '2px', width: '70px', backgroundColor: 'orange', border: '1px solid orange' }}></hr>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', fontSize: '18px' }}>If you have any queries, simply send us your questions or concern by starting a new case and we'll get you the help you need.</div>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                      Your Query has been sent successfully!
                    </Alert>
                </Snackbar>
                <form ref={form} onSubmit={sendEmail}>
                    <Grid container spacing={4} marginTop={1} justifyContent="center">
                        <Grid item xs={10} sm={10} md={4}>
                            <input value={firstName} onChange={((e) => setFirstName(e.target.value))} type="text" required placeholder="First Name" name="from_name" style={{ width: '100%', height: '35px', borderRadius: '7px', border: '1px solid #B7B7B7', paddingLeft: '10px', fontSize: '18px' }} />
                        </Grid>
                        <Grid item xs={10} sm={10} md={4}>
                            <input value={lasttName} onChange={((e) => setLasttName(e.target.value))} name="last_name" type="text" placeholder="Last Name" style={{ width: '100%', height: '35px', borderRadius: '7px', border: '1px solid #B7B7B7', paddingLeft: '10px', fontSize: '18px' }} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} marginTop={0.5} justifyContent="center">
                        <Grid item xs={10} sm={10} md={4}>
                            <input value={email} onChange={((e) => setEmail(e.target.value))} type="email" name="from_email" required placeholder="Email Id" style={{ width: '100%', height: '35px', borderRadius: '7px', border: '1px solid #B7B7B7', paddingLeft: '10px', fontSize: '18px' }} />
                        </Grid>
                        <Grid item xs={10} sm={10} md={4}>
                            <input value={contactNumber} onChange={((e) => setContactNumber(e.target.value))} type="text" name="contact_number" required placeholder="Mobile No." style={{ width: '100%', height: '35px', borderRadius: '7px', border: '1px solid #B7B7B7', paddingLeft: '10px', fontSize: '18px' }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4} marginTop={0.5} justifyContent="center">
                        <Grid item xs={10} sm={10} md={8}>
                            <select onChange={((e) => setSelectOption(e.target.value))} value={selectOption} name="select_package"
                                placeholder="Select tour package"
                                style={{ width: '102%', height: '43px', borderRadius: '7px', border: '1px solid #B7B7B7', paddingLeft: '10px', fontSize: '18px' }}
                            >
                                <option value='1' >Select</option>
                                {options.map((option)=>(
                                <option value={option.value}>{option.label}</option>
                            ))}
                            </select>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4} marginTop={0.5} justifyContent="center">
                        <Grid item xs={10} sm={10} md={8}>
                            <textarea value={message} onChange={((e) => setMessage(e.target.value))} className="vertical" type="text" name="message" required placeholder="Message (If any)" style={{ width: '100%', height: '45px', borderRadius: '7px', border: '1px solid #B7B7B7', padding: '10px', fontSize: '18px' }} />
                        </Grid>
                    </Grid>
                    <Grid container marginTop={3} marginBottom={3} justifyContent="center">
                        <Grid item xs={10} sm={10} md={8}>
                            <Button type="submit" variant="contained" sx={style}>SUBMIT</Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <div>
            </div>
        </>
    );
}
export default ContactUs;

